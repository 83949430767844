import { get } from "sites-common/utils/lodash";
import { validateRE } from "sites-common/utils/validations";
import { displayError, displayAlert } from "sites-common/utils/customErrors";
import useDonationConf from "../hooks/useDonationConf";

// Sample Restrictions JSON
// {
//  "errors": {
//      "error-default": "Error in information entered",
//      "error-name": "Invalid Name",
//      "warning-in-pan": "Pancard is required for Income Tax Benefit u/s 80G. With no pancard, the organization needs to pay 30% tax on this donation."
//  }
//  "restrictions": [
//     {
//         "comment": "restrict overseas in india donation pages",
//         "conds": [
//             [ ":sysCountry", "==", "in" ],
//             "&&",
//             [ [ ":citizenship", "IS", "NOTNULL" ], "&&", [ ":citizenship", "!=", "India" ] ]
//         ],
//         "msg" : "<div>Currently on account of change in Government regulations, we are not in a position to collect donations from Overseas Citizens through a website. However you can donate via NEFT/RTGS.<br /> <br/> Kindly email to <a target=\"_blank\" href=\"mailto:info.accounts@sahajmarg.org\"> info.accounts@sahajmarg.org </a> to get Bank details for transfer via NEFT/RTGS/SWIFT</div>"
//     }
//  ]
// }
//
// This file is now available on the website https://static-file.web.app/conf/donations/config.json
// and is auto deployed from the repo: https://github.com/HeartfulnessInstitute/gatsby-static-website
// For any changes required to restrictions, please update the file in the repo above and the changes
// be available immediately in the website upon check-in to the repo
//

const conf_dflt = {
  errors: {
    "error-default": "Error in information entered",
    "warning-in-pan":
      "Pancard is required for Income Tax Benefit u/s 80G. With no pancard, the organization needs to pay 30% tax on this donation.",
    "warning-in-identity":
      "Pancard is required for Income Tax Benefit u/s 80G.",
    "error-in-aadhar": "Valid Aadhar Card has 12 digits",
    "error-in-identification-number": "Invalid Identification Number",

    // RECURRING ONLINE DONATION
    "error-online-start-date": "Start date is required",
    "error-online-account-type": "Account type is required",
    // RECURRING OFFLINE DONATION
    "error-offline-payment-option": "Please select payment option",
    // RECURRING OFFLINE - STANDING INSTRUCTION - INTERNET BANKING
    "error-offline-net-banking-donor-bank": "Please enter donor bank",
    "error-offline-net-banking-donor-ifsc": "Please enter donor ifsc code",
    "error-offline-net-banking-standing-date": "Please choose a standing date",
    // RECURRING OFFLINE - STANDING INSTRUCTION - WALLET
    "error-offline-wallet-mobile": "Please enter wallet mobile",
    "error-offline-wallet-standing-date": "Please choose a standing date",
  },
  restrictions: [],
}; // require("./r.json");

// str(2019-12-31) => date(Dec 31, 2019 23:01:59)
function formatOdooDate2Sys(dstr) {
  if (dstr !== undefined && dstr !== null && dstr !== "" && dstr !== false) {
    try {
      const parts = dstr.substring(0, 10).split("-");
      return new Date(
        parseInt(parts[0], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[2], 10)
      );
    } catch (e) {
      displayError(e);
    }
  }
  return "";
}

function daysDiff(str1, str2) {
  const first = formatOdooDate2Sys(str1);
  const second = formatOdooDate2Sys(str2);
  const diff = Math.round((first - second) / (1000 * 60 * 60 * 24));
  return diff;
}

// date(Dec 31, 2019 23:01:59) => str(2019-12-31)
function formatSys2OdooDate(date) {
  try {
    const d = date.getDate();
    const m = date.getMonth() + 1; // Month from 0 to 11
    const y = date.getFullYear();
    const pad = (x) => (x <= 9 ? `0${x}` : x);
    return `${y}-${pad(m)}-${pad(d)}`;
  } catch (e) {
    return null;
  }
}

function computeRule(ruleX, state, at_element) {
  // console.log("ComputeRule", rule, state, at_element);
  // format of a rule
  // [ [":variable", "+", "string"], "-", 27 ]
  // state is dict containing :variable as a key
  const date_pattern = /^\d\d\d\d-\d\d-\d\d$/;
  let root = [];
  if (typeof at_element === "string") {
    root = [at_element];
  } else if (Array.isArray(at_element)) {
    root = [...at_element];
  }
  root.splice(root.length - 1, 1);
  // console.log("root", root)
  let process;
  const getval = (val) => {
    if (Array.isArray(val)) {
      return process(val);
    }
    if (val instanceof Date) {
      return daysDiff(formatSys2OdooDate(val), "1980-01-01");
    }
    if (typeof val === "string") {
      if (val.startsWith(":")) {
        let v;
        if (val.startsWith(":~")) {
          v = val.substring(2);
          v = [...root, v];
        } else {
          v = val.substring(1);
        }
        if (v === ":TODAY") {
          return daysDiff(formatSys2OdooDate(new Date()), "1980-01-01");
        }
        if (v === ":TODAY_SHORT") {
          return formatSys2OdooDate(new Date());
        }
        if (v === ":TODAY_YEAR") {
          return new Date().getFullYear();
        }
        if (date_pattern.test(get(state, v, ""))) {
          return daysDiff(get(state, v), "1980-01-01");
        }
        return get(state, v);
      }
      if (date_pattern.test(val)) {
        return daysDiff(val, "1980-01-01");
      }
      return val;
    }
    return val;
  };

  process = (rule) => {
    const [rule1, rule2, rule3] = rule;
    const a = getval(rule1);
    const op = rule2;
    const b = getval(rule3);

    // console.log("a", a, "op", op, "b", b);
    switch (op) {
      case "IS":
        if (b === "SET" || b === "NOTNULL") {
          return !!a;
        }
        if (b === "EMPTY" || b === "NULL") {
          return !a;
        }
        if (b === "ARRAY") {
          return Array.isArray(a);
        }
        return a === b;

      case "IN":
        if (typeof b === "string") {
          return b.search(a) > -1;
        }
        if (typeof b === "object") {
          return a in b;
        }
        return null;

      case "LIKE":
        return validateRE(b, a);
      case "+":
        return a + b;
      case "-":
        return a - b;
      case "/":
        return Math.round(a / b);
      case "*":
        return a * b;
      case "%":
        return a % b;
      case ">":
        return a > b;
      case ">=":
        return a > b;
      case "<":
        return a < b;
      case "<=":
        return a <= b;
      case "==":
        return a === b;
      case "!=":
        return a !== b;
      case "&&":
        return a && b;
      case "||":
        return a || b;
      case "XOR":
        return a ? !b : b;
      case "XAND":
        return a ? b : !b;
      case "=>": // implies operator
        return a ? b : true;
      case "IF":
        if (b) {
          return a;
        }
        return false;

      case "ELSE":
        if (a) {
          return a;
        }
        return b;

      case "SWITCH_DICT":
        if (typeof a === "string" && typeof b === "object") {
          if (a in b) {
            return b[a];
          }
          if ("##DEFAULT##" in b) {
            return b["##DEFAULT##"];
          }
          return "##IGNORE##";
        }
        return "##IGNORE##";
      default:
        return "ERROR";
    }
  };
  return process(ruleX);
}

/*
    Format of Constraints
    triplet style
    cond is [['a', '==', 'a'], '&&', [':b', '!=', 21]]
    [ {conds: ["is_prefect", "=", true], msg: "Participants should be a Prefect"}
*/
function validateConstraints(constraints, state) {
  const errors = [];
  if (!state) {
    displayAlert("Something Wrong");
  }
  try {
    constraints.forEach((constraint) => {
      const { conds, msg } = constraint;
      if (!conds || !state) {
        errors.push("Invalid Constraints");
      }
      const val = computeRule(conds, state, []);
      // console.log( "cond", msg, conds, cidx, val);

      if (val === true) {
        errors.push(msg);
      } else {
        // do nothing
      }
    });
  } catch (error) {
    errors.push(`Invalid Constraints: ${error}`);
  }
  return errors;
}

export const checkRestrictions = ({
  conf,
  country,
  donationId,
  amount,
  donorInfo = {},
}) => {
  const oneObject = {
    sysCountry: country,
    sysAmount: amount,
    sysDonationId: donationId,
    ...donorInfo,
  };

  const r = validateConstraints(
    get(conf, "restrictions", conf_dflt.restrictions),
    oneObject
  );
  // console.log(oneObject, conditions)
  return r;
};

const default_error = "Error in information entered";

export const errorStrings = ({ conf, error }) => {
  const errors = get(conf, "errors", conf_dflt.errors);
  if (typeof error === "string") {
    return get(errors, error, default_error);
  }
  if (typeof error === "object") {
    return `${get(errors, error.code, default_error)} - ${error.message}`;
  }
  return default_error;
};

const defaultFund = {
  country: "in",
  enableRecurring: false,
  enableRecurringOffline: false,
  enableOffline: false,
  showOtherCitizen: true,
  isOfflineReadOnly: false,
};

const defaultCountryLevelProps = {
  initialProfile: {
    name: "",
    email: "",
    phone: "+91",
    address: "",
    citizenship: "India",
    citizenship_id: 358,
    indian: true,
    city_id: "",
    city: "",
    state: "",
    country: "India",
    identificationType: "Pancard",
    identificationNumber: "",
    memberId: "",
  },
  initialCurrency: "INR",
};

export const DonationConfProps = (donationId) => {
  const { conf } = useDonationConf();

  const donationFeature = get(conf, "donation-feature", {});
  const donationFunds = get(donationFeature, donationId, defaultFund);
  const countryLevelProps = get(
    conf,
    "country-level-props",
    defaultCountryLevelProps
  );

  return { ...donationFunds, ...countryLevelProps[donationFunds.country] };
};
