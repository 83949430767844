/** @jsx jsx */

import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  jsx,
  Flex,
  Box,
  Grid,
  ThemeProvider,
  Card,
  Spinner,
  Checkbox,
  Label,
} from "theme-ui";
import { StringParam, useQueryParam } from "use-query-params";
import { Modal } from "gatsby-plugin-hfn-profile/components/Modal";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { DynamicFieldStyle1 as DynamicField } from "sites-common/components/DynamicField";
import { DayPickerInput } from "gatsby-plugin-hfn-profile/components/ui";
import { formatToIST } from "gatsby-plugin-hfn-profile/components/ui/Feature/RecurringDonations/utils";
import { PresetThemes } from "gatsby-plugin-hfn-profile/shared";
import adaptTheme from "sites-common/gatsby-utils/adaptTheme";
import ErrorsOrWarnings from "gatsby-plugin-hfn-profile/components/DonationComponent/DonationWidget/ErrorsOrWarnings";
import LayoutHome from "../layout/LayoutHome";

const UpdateRecurring = () => {
  const [trkId] = useQueryParam("trkId", StringParam);
  const [amnt] = useQueryParam("amount", StringParam);
  const [first_name] = useQueryParam("firstName", StringParam);
  const [last_name] = useQueryParam("lastName", StringParam);
  const [adrs] = useQueryParam("address", StringParam);
  const [profile_city] = useQueryParam("city", StringParam);
  const [city_id] = useQueryParam("cityId", StringParam);
  const [profile_state] = useQueryParam("state", StringParam);
  const [profile_country] = useQueryParam("country", StringParam);
  const [postal_code] = useQueryParam("postalCode", StringParam);
  const [status] = useQueryParam("status", StringParam);
  const [message] = useQueryParam("message", StringParam);
  const [start_date] = useQueryParam("startDate", StringParam);
  const [end_date] = useQueryParam("endDate", StringParam);
  const [doProfile, setProfile] = useState({});
  const [profileError, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recurringPaymentAction] = useQueryParam(
    "recurringPaymentAction",
    StringParam
  );
  const [billingAccountName] = useQueryParam("billingAccountName", StringParam);
  const [showErrorMsg, setShowErrorMsg] = useState("");
  const methodType = "POST";
  const [untilCancelled, setUntilCancelled] = useState(false);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            gatsbyDonationRecurringServiceBaseUri
          }
        }
      }
    `
  );

  const { gatsbyDonationRecurringServiceBaseUri } = site.siteMetadata;

  useEffect(() => {
    setProfile({
      trkId,
      amount: amnt,
      firstName: first_name,
      lastName: last_name,
      address: adrs,
      city: profile_city,
      cityId: city_id,
      state: profile_state,
      country: profile_country,
      postalCode: postal_code,
      billingAccountName,
      startDate: start_date,
      endDate: end_date !== "Until Canceled" ? new Date(end_date) : "",
    });
    setUntilCancelled(end_date === "Until Canceled");
  }, [
    trkId,
    amnt,
    first_name,
    last_name,
    adrs,
    profile_city,
    city_id,
    profile_state,
    profile_country,
    postal_code,
    billingAccountName,
    start_date,
    end_date,
  ]);

  const theme1 = () => adaptTheme({ ...PresetThemes.bootstrap }, [trkId]);

  const containerStyle = {
    mb: 1,
    borderBottom: `thin black solid !important`,
  };

  function createRequest(url, data, method) {
    const reqParam = {
      method,
      headers: new Headers({ "Content-type": "application/json" }),
    };
    if (method === "POST") {
      Object.assign(reqParam, {
        body: JSON.stringify(data),
      });
    }

    return fetch(url, reqParam)
      .then((res) => res.ok && res.json().then((json) => json))
      .catch((err) => {
        return `${err}url:${url}`;
      });
  }

  const doSetProfile = useCallback(
    (v, k, extras) => {
      setProfile({
        ...doProfile,
        [k]: v,
        ...(extras || {}),
      });
    },
    [doProfile]
  );

  const doValidation = (info) => {
    const errors = [];
    const formFields = [
      { f: "firstName", e: "error-name" },
      { f: "lastName", e: "error-lastName" },
      { f: "address", e: "error-address" },
      { f: "cityId", e: "error-city" },
      { f: "postalCode", e: "error-pincode" },
    ];

    if ((amnt in info && !info.amount) || info.amount < 1) {
      errors.push("error-amount");
    }

    formFields.map((x) => {
      if (x.f in info) {
        if (!info[x.f]) {
          errors.push(x.e);
        }
      }
      return false;
    });
    setError(errors);
    return errors;
  };

  const recurringOptionUpdate = (action) => {
    const {
      firstName,
      lastName,
      address,
      city,
      state,
      country,
      postalCode,
      amount,
      endDate,
      cityId,
    } = doProfile;
    let error = [];
    const postData = {
      trackingId: trkId,
      otherDetail: {
        pageUrl: `updateRecurring/`,
      },
      paymentGatewayAccount: {
        name: billingAccountName,
      },
    };

    if (action === "preBillingDetailUpdate") {
      Object.assign(postData, {
        customerDetail: {
          firstName,
          lastName,
          address,
          city,
          state,
          country,
          postalCode,
          cityId,
        },
      });
      error = doValidation(postData.customerDetail);
    } else if (action === "scheduleDetailUpdate") {
      Object.assign(postData, {
        customerDetail: {
          amount,
          endDate,
        },
      });
      error = doValidation(postData.customerDetail);
    }

    if (!error.length) {
      setLoading(true);
      createRequest(
        `${gatsbyDonationRecurringServiceBaseUri}${action}`,
        postData,
        methodType
      )
        .then((res) => {
          if (res) {
            setLoading(false);
            if (res.paymentGatewayUrl || res.redirectUrl) {
              window.location.href = res.paymentGatewayUrl || res.redirectUrl;
            } else if (res.errorMessage) {
              setShowErrorMsg(res.errorMessage);
            }
          }
        })
        .catch((err) => {
          return `${err} recurring updation failed url: ${action}`;
        });
    }
  };

  const renderBillingDetailsForm = () => {
    return (
      <Box sx={{ width: "400px" }}>
        <DynamicField
          containerStyle={containerStyle}
          name="firstName"
          label="First Name *"
          icon="user"
          value={doProfile.firstName}
          onChange={doSetProfile}
        />
        <DynamicField
          containerStyle={containerStyle}
          name="lastName"
          label="Last Name *"
          icon="user"
          value={doProfile.lastName}
          onChange={doSetProfile}
        />
        <DynamicField
          containerStyle={containerStyle}
          name="address"
          label="Street Address *"
          icon="home"
          value={doProfile.address}
          onChange={doSetProfile}
        />
        <DynamicField
          zq="in"
          containerStyle={containerStyle}
          name="cityId"
          label="City *"
          type="city_id_nostrict_allownew"
          options={{ city: "name", state: "state", country: "country" }}
          value={doProfile.cityId}
          onChange={doSetProfile}
        />
        <DynamicField
          containerStyle={containerStyle}
          name="postalCode"
          icon="star-of-david"
          label="Zip Code *"
          value={doProfile.postalCode}
          onChange={doSetProfile}
          inputTransform={(r) => r.toUpperCase().trim()}
        />
      </Box>
    );
  };

  const setEndDate = (startDate) =>
    new Date(new Date(startDate).setMonth(new Date(startDate).getMonth() + 3));

  const endDateInput = React.useMemo(
    () => ({
      value: doProfile.endDate,
      inputProps: {
        readOnly: true,
      },
      dayPickerProps: {
        selectedDays: doProfile.endDate,
        onDayClick: (endDate1, { disabled }) =>
          !disabled && doSetProfile(formatToIST(endDate1), "endDate"),
        initialMonth: doProfile.endDate || setEndDate(doProfile.startDate),
        modifiers: {
          disabled: {
            before: setEndDate(doProfile.startDate) || new Date(),
          },
        },
      },
    }),
    [doProfile.endDate, doProfile.startDate, doSetProfile]
  );

  const renderScheduleDetailsForm = () => {
    return (
      <Box sx={{ width: "400px" }}>
        <DynamicField
          containerStyle={containerStyle}
          name="amount"
          icon="dollar-sign"
          label="Amount *"
          type="number"
          value={doProfile.amount}
          onChange={doSetProfile}
        />
        <DynamicField
          sx={{ pointerEvents: "none" }}
          containerStyle={containerStyle}
          label="Start Date"
          name="startDate"
          value={`Selected Start Date: ${doProfile.startDate}`}
          onChange={() => {}}
        />
        <Grid columns={2}>
          <Box>
            <Label>End Date</Label>
            <Label>
              <Checkbox
                sx={{ cursor: "pointer" }}
                onChange={() => {
                  setUntilCancelled(!untilCancelled);
                  doSetProfile("", "endDate");
                }}
                checked={untilCancelled}
              />
              Until Cancelled
            </Label>
          </Box>
          {!untilCancelled && (
            <DayPickerInput
              {...endDateInput}
              inputProps={{
                ...endDateInput.inputProps,
                variant: "border-bottom",
              }}
              label="End Date"
            />
          )}
        </Grid>
      </Box>
    );
  };

  const renderSaveButton = (recurringAction) => (
    <Box>
      <Button bg="secondary" mr={2} mt={3} onClick={() => navigate("/")}>
        Cancel
      </Button>
      {loading ? (
        <Button bg="green" mr={2} mt={3} sx={{ minWidth: "8em" }}>
          <Spinner size={20} color="white" />
        </Button>
      ) : (
        <Button
          bg="green"
          onClick={() => recurringOptionUpdate(recurringAction)}
        >
          {recurringPaymentAction === "scheduleDetailUpdate"
            ? "Update"
            : "Save and Continue"}
        </Button>
      )}
    </Box>
  );

  const renderDetails = (recurringAction) => {
    let renderElement;
    switch (recurringAction) {
      case "scheduleDetailUpdate":
        renderElement = (
          <Box sx={{ mt: 3, textAlign: "center" }}>
            Use this form to change the amount or number of installments for
            this recurring contribution. Changes will be automatically sent to
            the payment processor. You can not change the contribution
            frequency.
            <br />
            <Flex sx={{ justifyContent: "center", mt: 5 }}>
              {renderScheduleDetailsForm()}
            </Flex>
            {renderSaveButton(recurringAction)}
          </Box>
        );
        break;
      case "preBillingDetailUpdate":
        renderElement = (
          <Box sx={{ mt: 3, textAlign: "center" }}>
            Use this form to update the credit card and billing name and address
            used for this recurring contribution.
            <br />
            <b sx={{ mt: 3 }}>Billing Name and Address</b>
            <Flex sx={{ justifyContent: "center", mt: 5 }}>
              {renderBillingDetailsForm()}
            </Flex>
            {renderSaveButton(recurringAction)}
            <Box sx={{ fontSize: "12px", mt: 2 }}>
              By proceeding, you will be navigate to{" "}
              <b>Authorize.net secure payment</b> card details page.
            </Box>
          </Box>
        );
        break;
      case "unsubscribe":
        renderElement = (
          <Box sx={{ mt: 3, textAlign: "center" }}>
            Click the button below to cancel this commitment and stop future
            transactions. This does not affect contributions which have already
            been completed.
            <Box sx={{ textAlign: "center" }}>
              <Button
                bg="secondary"
                mr={2}
                mt={3}
                onClick={() => navigate("/")}
              >
                Not Now
              </Button>
              {loading ? (
                <Button bg="green" sx={{ minWidth: "9em" }}>
                  <Spinner size={20} mr={2} color="white" />
                </Button>
              ) : (
                <Button
                  bg="green"
                  mr={2}
                  onClick={() => recurringOptionUpdate(recurringAction)}
                >
                  Cancel Recurring Contribution
                </Button>
              )}
            </Box>
          </Box>
        );
        break;
      default:
        break;
    }

    return renderElement;
  };

  const ModalTitleBar = () => {
    return (
      <div sx={{ height: "50px", bg: "forestgreen", color: "white" }}>
        <Flex>
          <Box
            px={2}
            sx={{ paddingTop: "10px", flex: "1 1 auto", bg: "forestgreen" }}
          >
            {status === "success" && <span>Success</span>}
            {status === "failure" && <span>Failed</span>}
          </Box>
          <Box sx={{ textAlign: "end", margin: "10px" }}>
            <div
              role="button"
              tabIndex={0}
              onClick={() => navigate("/")}
              onKeyDown={() => navigate("/")}
            >
              x
            </div>
          </Box>
        </Flex>
      </div>
    );
  };

  const renderTitleText = (key) => {
    let titleText;
    if (key === "scheduleDetailUpdate") {
      titleText = "Update Schedule";
    } else if (key === "preBillingDetailUpdate") {
      titleText = "Update your Billing and Card Information";
    } else if (key === "unsubscribe") {
      titleText = "Cancel Your Recurring Donation";
    }
    return titleText;
  };

  return (
    <LayoutHome
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3>{renderTitleText(recurringPaymentAction)}</h3>}
      seoTitle=""
    >
      <ThemeProvider theme={theme1}>
        {status && (
          <Modal
            title={ModalTitleBar()}
            style_container={{ minHeight: "25%" }}
            style_main={{
              top: "30%",
              padding: "0",
              overflowY: "auto",
              maxHeight: "400px",
              minHeight: "25%",
              borderRadius: 0,
              backgroundColor: "white",
            }}
          >
            <Grid
              columns={1}
              sx={{
                textAlign: "center",
                padding: "20px",
                overflowWrap: "break-word",
              }}
            >
              <Box sx={{ flex: "1", minHeight: "50px" }}>
                {message}
                <hr />
                <Box>Tracking Id: {trkId}</Box>
                <Box>Amount: {amnt}</Box>
              </Box>
              <Box sx={{ flex: "1", paddingBottom: "10px" }}>
                <Button bg="forestgreen" onClick={() => navigate("/")}>
                  Close
                </Button>
              </Box>
            </Grid>
          </Modal>
        )}
        {renderDetails(recurringPaymentAction)}
        <br />
        {showErrorMsg && (
          <div sx={{ textAlign: "center", color: "red" }}>{showErrorMsg}.</div>
        )}
        {profileError.length > 0 && (
          <Card sx={{ textAlign: "center" }}>
            <b>Form Errors! Please fix to continue</b>
            <ErrorsOrWarnings errors={profileError} />
          </Card>
        )}
        <p sx={{ pb: "50%" }} />
      </ThemeProvider>
    </LayoutHome>
  );
};

export default UpdateRecurring;
